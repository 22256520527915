import React from "react";
import styled from "styled-components";

export default function Card({ image, name, temperaments, weightM }) {
  return (
    <Container>
      <CardImg>
        <img src={`${image.url}`} alt={`imagen de: ${name}`} />
      </CardImg>
      <h2>{name}</h2>
      <DivText>
        <h3>{temperaments}</h3>

        <h4>{`${weightM} Kg`}</h4>
        {/* {temperaments?.map((e) => (
          <h3 key={name}>{e.temperaments}</h3>
        ))} */}
      </DivText>
    </Container>
  );
}

const Container = styled.div`
  font-family: "Courier New", Courier, monospace;
  box-shadow: 0px 0px 20px #ccc;
  color: #000000;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 2em 2em;
  border-radius: 30px;

  max-width: 16rem;
  height: auto;
  margin: 1em;
  transition: 0.3s;

  :hover {
    transform: scale(1.05);
    background-color: #3df9ad;
  }
`;

const CardImg = styled.div`
  width: 100%;
  height: 6rem;

  img {
    max-width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 5px;
  }
`;

const DivText = styled.div`
  width: 60%;
`;
