import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { dogDetail, Clean } from "../../redux/actions";
import image from "../image/detail.png";
import styled from "styled-components";
import Header from "../Header/Header";

function DogDetails() {
  const detail = useSelector((state) => state.dogDetail);

  const dispatch = useDispatch();
  let { id } = useParams();

  let imageDog, nameDog, heightDog, weightDog, lifeSpanDog, temperamentDog;

  if (detail.name) {
    imageDog = detail.image.url;
    nameDog = detail.name;
    heightDog = detail.height;
    weightDog = detail.weight;
    lifeSpanDog = detail.life_span;
    temperamentDog = detail.temperament;
  }

  useEffect(() => {
    dispatch(dogDetail(id));
    dispatch(Clean(id));
  }, [dispatch, id]);

  if (detail.life_span) {
    return (
      <Container>
        <Button>
          <Link to="/home">Home</Link>
        </Button>

        <img src={imageDog} alt={`imagen de ${nameDog}`} />

        <Description>
          <h1>{nameDog}</h1>

          <h3>{`Height: ${heightDog.metric} CM`}</h3>

          <h3>{`Weight: ${weightDog.metric} KG`}</h3>

          <h3>{`Lifespan: ${lifeSpanDog}`}</h3>

          <p>
            {
              <h3>{`Temperament: ${
                temperamentDog ? temperamentDog : "Sin temperamentos"
              }`}</h3>
            }
          </p>
        </Description>
      </Container>
    );
  }
  return (
    <>
      <Header />
      <Load>
        <img
          src="https://2.bp.blogspot.com/-dasNy7iU9qM/V73ID65xJlI/AAAAAAAAB10/x24HvcYTtjA74LErOIxjbT2HQswlDuRpwCLcB/s1600/perro%2B4.gif"
          alt="Sin imagen"
        ></img>
      </Load>
    </>
  );
}

export default DogDetails;

const Load = styled.div`
  width: auto;
  height: 97vh;
  position: relative;
  display: flex;
  background-color: #fff200;
  flex-direction: column;

  img {
    position: fixed;
    left: 40%;
  }
`;

const Container = styled.div`
  font-family: "Courier New", Courier, monospace;
  background-color: #fff200;
  background-image: url(${image});
  background-size: 83%;
  background-repeat: no-repeat;
  background-position: center;

  width: auto;
  height: 97vh;
  position: relative;
  img {
    max-width: 300px;
    height: 300px;
    object-fit: contain;
    position: absolute;
    left: 43%;
    top: 35%;
  }
`;

const Button = styled.button`
  font-family: "Courier New", Courier, monospace; //agrandar!
  cursor: pointer;
  font-size: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: yellow;
  border-width: 3px;
  border-radius: 15px;
  box-shadow: 4px 4px 0 4px black;
  margin-left: 1rem;
  margin-top: 1rem;
`;
const Description = styled.div`
  position: absolute;
  left: 28%;
  top: 0%;
  p {
    padding-left: 0px;
    padding-right: 350px;
  }
`;
