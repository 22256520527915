import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Card from "../Card/Card";
import styled from "styled-components";

function Cards({ currentPage }) {
  let allDogs = useSelector((state) => state.allDogs);
  const dogsPerPage = 8;
  const lastIndex = currentPage * dogsPerPage;
  const firstIndex = lastIndex - dogsPerPage;
  const currentDogs = allDogs?.slice(firstIndex, lastIndex);

  return (
    <Container>
      {!currentDogs ? (
        <p>LOADING...</p>
      ) : (
        currentDogs?.map((e) => {
          return (
            <div key={e.id}>
              <Link to={"/dog-detail/" + e.id}>
                {
                  <Card
                    key={e.id}
                    image={e.image}
                    name={e.name}
                    temperaments={e.temperament}
                    weightM={e.weight.metric}
                    weightI={e.weight.imperial}
                  />
                }
              </Link>
            </div>
          );
        })
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
`;
export default Cards;
