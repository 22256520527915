import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Git from "./image/Git2.png";
import Linkedin from "./image/Linkedin.png";
import image from "../image/top.png";

function Header() {
  return (
    <>
      <BackDiv>
        <Divleft>
          <Link to={"/"}>
            <img src={image} alt="Sin imagen"></img>
          </Link>
        </Divleft>

        <DivCenter>
          <p>PI Henry</p>
          <p>Alejandro Romero</p>
          <a href="https://www.linkedin.com/in/alejandro-david-romero-190128240/">
            <Img src={Linkedin} alt="linkedin"></Img>
          </a>
          <a href="https://github.com/alejandrodavidromero">
            <Img src={Git} alt="Github"></Img>
          </a>
        </DivCenter>

        <DivRight>
          <Link to={"/dog"}>
            <Button>Crear GUAU</Button>
          </Link>
        </DivRight>
      </BackDiv>
    </>
  );
}
const Img = styled.img`
  width: 48px;
  height: 48px;
  background-color: transparent;
  margin-left: auto;
  margin-right: auto;
`;

const BackDiv = styled.div`
  display: flex;
  padding: 0px 1rem;
  justify-content: space-between;
  background-color: rgba(61, 249, 173, 0.8);

  font-family: "Courier New", Courier, monospace;
  width: auto;
  height: auto;
`;
const Divleft = styled.div`
  width: 25%;
  img {
    width: 100%;
  }
`;

const DivCenter = styled.div`
  text-align: left;
  font-size: 1.5em;
  width: 40%;
  display: flex;
  align-items: center;
  gap: 1em;
`;

const DivRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
`;

const Button = styled.button`
  font-family: "Courier New", Courier, monospace;
  cursor: pointer;
  font-size: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: yellow;
  border-width: 3px;
  border-radius: 15px;
  box-shadow: 4px 4px 0 4px black;
`;

export default Header;
