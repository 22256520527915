import React, { useState, useEffect } from "react";
import { getAllDogs, getTemperament } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import Header from "../Header/Header";
import Pagination from "../Pagination/Pagination";
import Select from "../Select/Select";
import Cards from "../Cards/Cards";
import image from "../image/home.png";
import styled from "styled-components";

function Home() {
  let allDogs = useSelector((state) => state.allDogs);
  let orderDogs = useSelector((state) => state.orderDogs);
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [renderPage, setRenderPage] = useState(true);

  useEffect(() => {
    if (renderPage) {
      setRenderPage(false);
      if (allDogs.length < 1) {
        dispatch(getAllDogs());
        dispatch(getTemperament());
      }
    }
  }, [orderDogs, allDogs]);

  return (
    <Container>
      <Header />
      <Select />
      <Pagination allDogs={allDogs?.length} paginate={paginate} />
      <hr />
      <Cards currentPage={currentPage} />
      <hr />
    </Container>
  );
}

const Container = styled.div`
  background-image: url(${image});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-position: 50% 45%;
  background-color: #f3b757;
  background-attachment: fixed;
`;

export default Home;
