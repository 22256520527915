import React from "react";
import styled from "styled-components";

const Pagination = ({ allDogs, paginate }) => {
  const pageNumbers = [];
  const dogsPerPage = 8;
  for (let i = 1; i <= Math.ceil(allDogs / dogsPerPage); i++) {
    pageNumbers.push(i);
  }

  const onNumberClick = (number) => {
    paginate(number);
  };
  return (
    <Container>
      <div>
        {pageNumbers.map((number) => (
          <p key={number} onClick={() => onNumberClick(number)}>
            {number}
          </p>
        ))}
      </div>
    </Container>
  );
};

export default Pagination;

const Container = styled.nav`
  background-color: #3df9ad;
  text-align: center;
  div {
    display: inline-block;
    margin-top: 1em;
  }
  p {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    border: 1px solid #ddd;
    margin: 0 4px;
    cursor: pointer;
  }
`;
