import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getTemperament, postDog } from "../../redux/actions";
import image from "../image/background.jpg";
import styled from "styled-components";

function CreateDog() {
  const temperaments = useSelector((state) => state.temperament);
  const dispatch = useDispatch();

  const [form, setForm] = useState({
    name: "",
    min_height: "",
    max_height: "",
    min_weight: "",
    max_weight: "",
    life_span: "",
    image: "",
    temperaments: [],
  });

  const [button, setButton] = useState(false);

  const [errors, setErrors] = useState({
    name: "",
    min_height: "",
    max_height: "",
    min_weight: "",
    max_weight: "",
    life_span: "",
    image: "",
  });

  const validate = (form) => {
    let errors = {};
    if (!form.name) {
      errors.name = "Se requiere un Nombre";
    }
    if (!form.min_height || !form.max_height) {
      errors.height = "Las alturas son requeridas";
    }

    if (form.min_height >= form.max_height) {
      errors.height = "Revisar este campo";
    }

    if (!form.min_weight || !form.max_weight) {
      errors.weight = "El peso es requerido";
    }
    if (form.min_weight >= form.max_weight) {
      errors.height = "Revisar este campo";
    }

    if (!form.life_span) {
      errors.life_span = "Se requiere la esperanza de vida, por ej: 13 - 17";
    }

    return errors;
  };

  useEffect(() => {
    dispatch(getTemperament());
  }, []);

  useEffect(() => {
    if (
      form.name.length > 0 &&
      form.min_height.length > 0 &&
      form.max_height.length > 0 &&
      form.min_weight.length > 0 &&
      form.max_weight.length > 0 &&
      form.max.life_span !== "" &&
      form.temperaments !== []
    )
      setButton(true);
    else setButton(false);
  }, [form, button]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(postDog(form));
    alert("El GUAU fué creado correctamente!");
    setForm({
      name: "",
      min_height: "",
      max_height: "",
      min_weight: "",
      max_weight: "",
      life_span: "",
      image: "",
      temperaments: [],
    });
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    setErrors(
      validate({
        ...form,
        [e.target.name]: e.target.value,
      })
    );
  };

  const handleSelect = (e) => {
    setForm({
      ...form,
      temperaments: [...form.temperaments, e.target.value],
    });
  };

  const handleDelete = (e) => {
    setForm({
      ...form,
      temperaments: form.temperaments.filter((temp) => temp !== e),
    });
  };

  return (
    <Container>
      <Link to="/home">
        <Button>Home</Button>
      </Link>
      <form action="" id="form" onSubmit={handleSubmit}>
        <div>
          <Input
            type="text"
            value={form.name}
            name="name"
            onChange={(e) => handleChange(e)}
            placeholder="Nombre..."
          />
        </div>
        <div>{errors.name && <p>{errors.name}</p>}</div>{" "}
        <div>
          <div>
            <Input
              type="text"
              value={form.min_height}
              name="min_height"
              placeholder="Altura mínima"
              onChange={(e) => handleChange(e)}
            />
          </div>

          <div>
            <Input
              type="text"
              value={form.max_height}
              name="max_height"
              placeholder="Altura máxima"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div>{errors.height && <p>{errors.height}</p>}</div>
        <div>
          <div>
            <Input
              type="text"
              value={form.min_weight}
              name="min_weight"
              placeholder="Peso mínimo"
              onChange={(e) => handleChange(e)}
            />
          </div>

          <div>
            <Input
              type="text"
              value={form.max_weight}
              name="max_weight"
              placeholder="Peso máximo"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
        <div>{errors.weight && <p>{errors.weight}</p>}</div>
        <div>
          <Input
            type="text"
            autoComplete="off"
            name="life_span"
            value={form.life_span}
            placeholder="Esperanza de vida"
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div>{errors.life_span && <p>{errors.life_span}</p>}</div>
        <div>
          <Input
            type="text"
            autoComplete="off"
            value={form.image}
            name="image"
            placeholder="Imágen URL..."
            onChange={(e) => handleChange(e)}
          />
        </div>
        <h3>Seleccioná temperamentos</h3>
        <div>
          <select onChange={handleSelect}>
            <option defaultValue>Temperamentos:</option>
            {temperaments.map((e) => (
              <option value={e} key={e + Math.random()}>
                {e}
              </option>
            ))}
          </select>
        </div>
        <div>
          <Button type="submit" form="form">
            Crear nuevo GUAU
          </Button>
        </div>
      </form>

      <div>
        <div>
          <h2>Temperamentos</h2>
        </div>

        <div>
          {form.temperaments.map((e) => (
            <div key={e} onClick={() => handleDelete(e)}>
              <p>{`${e}`}</p>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
}

export default CreateDog;

const Container = styled.div`
  display: flex;
  height: 97.5vh;
  font-family: "Courier New", Courier, monospace;
  background-image: url(${image});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #3efaae;
`;

const Input = styled.input`
  font-family: "Courier New", Courier, monospace;
  width: 15rem;
  font-size: 1rem;
  border: 1px solid;
  border-radius: 15px;
  font-weight: bold;
  padding: 1em;
  margin: 4px 0;
`;

const Button = styled.button`
  font-family: "Courier New", Courier, monospace;
  cursor: pointer;
  font-size: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: yellow;
  border-width: 3px;
  border-radius: 15px;
  box-shadow: 4px 4px 0 4px black;
`;
