const intialState = {
  dogs: [],
  allDogs: [],
  temperament: [],
  dogDetail: [],
  orderDogs: "",
};

const rootReducer = (state = intialState, action) => {
  switch (action.type) {
    case "GET_ALL_DOGS":
      return {
        ...state,
        dogs: action.payload.data,
        allDogs: action.payload.data,
      };

    case "GET_TEMPERAMENT":
      const filteredTemp = action.payload.data.filter((e) => e !== "");
      return {
        ...state,
        temperament: filteredTemp,
      };

    case "GET_FILTER_TEMPERAMENTS": {
      const allDogs = state.dogs;
      let filteredDogs = [];
      if (action.payload === "All") {
        filteredDogs = allDogs;
      } else {
        for (let i = 0; i < allDogs.length; i++) {
          let found = allDogs[i].temperament;
          let foundTemperament = found?.includes(action.payload);
          if (foundTemperament) {
            filteredDogs.push(allDogs[i]);
          }
        }
      }
      return {
        ...state,
        allDogs: filteredDogs,
      };
    }

    case "ORDER_BY_NAME":
      const stateDogs = state.dogs;
      let orderByName = [];

      if (action.payload.name === "A-Z") {
        orderByName = stateDogs;
      } else {
        let reverseDogs = stateDogs.reverse();
        orderByName = reverseDogs;
      }
      return {
        ...state,
        allDogs: orderByName,
        orderDogs: action.payload,
      };

    case "ORDER_BY_WEIGHT":
      const stateWeightDogs = state.dogs;
      const orderByWeight =
        action.payload === "min_weight"
          ? stateWeightDogs.sort((a, b) => {
              if (
                parseInt(a.weight.metric.slice(0, 3)) <
                parseInt(b.weight.metric.slice(0, 3))
              )
                return 1;

              if (
                parseInt(b.weight.metric.slice(0, 3)) <
                parseInt(a.weight.metric.slice(0, 3))
              )
                return -1;

              return 0;
            })
          : stateWeightDogs.sort((a, b) => {
              if (
                parseInt(a.weight.metric.slice(0, 3)) <
                parseInt(b.weight.metric.slice(0, 3))
              )
                return -1;

              if (
                parseInt(b.weight.metric.slice(0, 3)) <
                parseInt(a.weight.metric.slice(0, 3))
              )
                return 1;

              return 0;
            });
      return {
        ...state,
        allDogs: orderByWeight,
      };

    // case "GET_DOGS_BY_BREEDS":
    //   const emptyBreeds = action.payload.data.filter((e) => e !== "");
    //   console.log(emptyBreeds);
    //   return {
    //     ...state,
    //     allDogs: emptyBreeds,
    //   };

    case "DOG_DETAIL":
      const detailDog = action.payload.data[0];

      return {
        ...state,
        dogDetail: detailDog,
      };

    case "CLEAN":
      return {
        ...state,
        dogDetail: [],
      };

    default:
      return state;
  }
};

export default rootReducer;
