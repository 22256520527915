import React from "react";
import { Route, Routes } from "react-router-dom";
import Landing from "./components/LandingPage/Landing";
import Home from "./components/Home/Home";
import DogDetails from "./components/DogDetail/DogDetails";
import CreateDog from "./components/CreateDog/CreateDog";

export function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Landing />} />
      <Route exact path="/home" element={<Home />} />
      <Route exact path="/dog-detail/:id" element={<DogDetails />} />
      <Route exact path="/dog" element={<CreateDog />} />
    </Routes>
  );
}

export default App;
