import axios from "axios";

export const GET_ALL_DOGS = "GET_ALL_DOGS";
export const GET_TEMPERAMENT = "GET_TEMPERAMENT";
export const GET_FILTER_TEMPERAMENTS = "GET_FILTER_TEMPERAMENTS";
export const ORDER_BY_WEIGHT = "ORDER_BY_WEIGHT";
export const ORDER_BY_NAME = "ORDER_BY_NAME";
export const GET_DOGS_BY_BREEDS = "GET_DOGS_BY_BREEDS";
export const DOG_DETAIL = "DOG_DETAIL";
export const CLEAN = "CLEAN";

export const getAllDogs = () => {
  return async function (dispatch) {
    const response = await axios.get("/dogs");
    dispatch({ type: "GET_ALL_DOGS", payload: response.data });
  };
};

export const getTemperament = () => {
  return async function (dispatch) {
    const response = await axios.get(`/temperament`);
    return dispatch({ type: "GET_TEMPERAMENT", payload: response.data });
  };
};

export const getFilterTemperaments = (temperament) => {
  return {
    type: "GET_FILTER_TEMPERAMENTS",
    payload: temperament,
  };
};

export function orderByName(payload) {
  return {
    type: "ORDER_BY_NAME",
    payload,
  };
}

export function orderByWeight(payload) {
  return {
    type: "ORDER_BY_WEIGHT",
    payload,
  };
}

// export function getDogsByBreeds(payload) {
//   return async function (dispatch) {
//     const response = await axios.get(`/dogs?name=${payload}`);
//     return dispatch({
//       type: "GET_DOGS_BY_BREEDS",
//       payload: response.data,
//     });
//   };
// }

export const dogDetail = (id) => {
  return async function (dispatch) {
    try {
      var response = await axios.get("/dogs/" + id);
      return dispatch({
        type: "DOG_DETAIL",
        payload: response.data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export function postDog(payload) {
  return async function () {
    const data = await axios.post("/dog", payload);
    return data;
  };
}

export function Clean() {
  return {
    type: "CLEAN",
  };
}
