import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  orderByName,
  orderByWeight,
  getFilterTemperaments,
} from "../../redux/actions";
import styled from "styled-components";

function Select() {
  const dispatch = useDispatch();

  const allTemperaments = useSelector((state) => state.temperament);
  const [orden, setOrden] = useState("");

  const handleOrderByName = (e) => {
    e.preventDefault();
    setOrden(`Ordenado ${e.target.value}`);
  };

  useEffect(() => {
    dispatch(orderByName(orden));
  }, [orden]);

  const handleOrderByWeight = (e) => {
    dispatch(orderByWeight(e.target.value));
    setOrden(`Ordenado ${e.target.value}`);
  };

  const handleFilterByTemperament = (e) => {
    dispatch(getFilterTemperaments(e.target.value));
  };

  return (
    <>
      <Container>
        <BoxSelect>
          <FilterSelect value={orden} onChange={handleOrderByName}>
            <option default>Orden alfabetico</option>
            <option value="A-Z">A-Z</option>
            <option value="Z-A">Z-A</option>
          </FilterSelect>
        </BoxSelect>

        <BoxSelect>
          <FilterSelect onChange={handleOrderByWeight}>
            <option default>Orden por peso</option>
            <option value="min_weight">Menor peso</option>
            <option value="max_weight">Mayor peso</option>
          </FilterSelect>
        </BoxSelect>

        <BoxSelect>
          <FilterSelect onChange={handleFilterByTemperament}>
            <option default>Orden por temperamentos</option>
            <option value="All">All</option>
            {allTemperaments?.map((e) => {
              return (
                <option value={e} key={e}>
                  {e}
                </option>
              );
            })}
          </FilterSelect>
        </BoxSelect>
      </Container>
    </>
  );
}

const Container = styled.div`
  columns: 3;
  display: flex;
  background-color: rgba(61, 249, 173, 0.8);
`;

const BoxSelect = styled.div`
  margin: 20px auto 40px auto;
  height: 30px;
  width: 250px;
  display: flex;
`;
const FilterSelect = styled.select`
  font-family: "Courier New", Courier, monospace;
  background: transparent;
  font-size: 14px;
  height: 3rem;
  padding: 8px;
  width: 250px;
  border-radius: 30px;
  border: 3px solid black;
`;
export default Select;
