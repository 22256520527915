import React from "react";
import styled from "styled-components";
import landing from "../image/landing.jpg";
import { Link } from "react-router-dom";

function Landing() {
  return (
    <Div>
      <Link to="/home">
        <Button>PLAY</Button>
      </Link>
    </Div>
  );
}

export default Landing;

const Div = styled.div`
  background: #f3d736;
  background-image: url(${landing});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-position: 50% 45%;

  display: flex;
  height: 97.5vh;
`;

const Button = styled.button`
  font-family: "Courier New", Courier, monospace;
  background-color: #ed1e61;
  cursor: pointer;
  color: #d5e024;

  position: absolute;
  left: 440px;
  top: 555px;

  width: 120px;
  height: 120px;
  border-radius: 50%;
  font-size: 2em;
  font-family: comic;

  margin-left: 0.8em;

  box-shadow: 5px 5px 5px 0px black;
  transition: 0.3s;

  :hover {
    box-shadow: 5px 5px 5px 0px #ed1e61;
    color: #ed1e61;
    background-color: white;
  }
`;
